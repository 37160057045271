var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.invoiceData.design)?_c('div',[_c('status-alert',{attrs:{"is-success":_vm.isSuccess,"error-data":_vm.errorData,"success-msg":_vm.successMsg}}),_c('b-row',[_c('b-col',{attrs:{"md":"12","lg":"12"}},[_c('validation-observer',{ref:"refFormRecurringData"},[_c('b-form',{staticClass:"p-2"},[_c('hr'),_c('div',[_c('b-col',{staticClass:"p-0",attrs:{"cols":"12"}},[_c('b-form-checkbox',{attrs:{"checked":"true","name":"check-button","switch":"","inline":"","value":true,"unchecked-value":false},model:{value:(_vm.recurringInvoiceData.enabled),callback:function ($$v) {_vm.$set(_vm.recurringInvoiceData, "enabled", $$v)},expression:"recurringInvoiceData.enabled"}},[_c('h4',[_vm._v(" Enable Recurring ")])])],1),_c('b-col',{attrs:{"md":"12","lg":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Recurring Period'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{staticClass:"p-0",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Recurring Period","label-for":"recurring-period","label-cols-md":"2"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.recurringPeriodOptions,"reduce":function (val) { return val.value; },"autocomplete":"chrome-off","clearable":false,"input-id":"recurring-period","state":errors.length > 0 ? false:null},on:{"input":_vm.calculateEndDate},model:{value:(_vm.recurringInvoiceData.recurring_period),callback:function ($$v) {_vm.$set(_vm.recurringInvoiceData, "recurring_period", $$v)},expression:"recurringInvoiceData.recurring_period"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)],1)]}}],null,false,797470613)}),_c('validation-provider',{attrs:{"name":_vm.$t('Start On'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{staticClass:"p-0",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Start On","label-for":"recurring-start-date","label-cols-md":"2"}},[_c('b-form-datepicker',{attrs:{"id":"recurring-start-date","local":"en","today-variant":"danger","state":errors.length > 0 ? false:null},on:{"input":_vm.calculateOccurrences},model:{value:(_vm.recurringInvoiceData.start_date),callback:function ($$v) {_vm.$set(_vm.recurringInvoiceData, "start_date", $$v)},expression:"recurringInvoiceData.start_date"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)],1)]}}],null,false,532442162)}),_c('b-col',{staticClass:"p-0",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Number of occurences","label-cols-md":"2","label-for":"subject"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-center column-is-mobile"},[_c('b-form-input',{directives:[{name:"numeric-only",rawName:"v-numeric-only"}],attrs:{"id":"subject","type":"number","size":"md","placeholder":"Enter number of occurrences","min":"1","step":"1","disabled":_vm.recurringInvoiceData.recur_until_cancel === 1},on:{"input":_vm.calculateEndDate},model:{value:(_vm.recurringInvoiceData.number_of_occurences),callback:function ($$v) {_vm.$set(_vm.recurringInvoiceData, "number_of_occurences", $$v)},expression:"recurringInvoiceData.number_of_occurences"}}),_c('b-form-checkbox',{staticClass:"ml-3 mt-1",attrs:{"checked":"true","name":"check-button","inline":"","value":1,"unchecked-value":0},on:{"input":_vm.calculateEndDate},model:{value:(_vm.recurringInvoiceData.recur_until_cancel),callback:function ($$v) {_vm.$set(_vm.recurringInvoiceData, "recur_until_cancel", $$v)},expression:"recurringInvoiceData.recur_until_cancel"}},[_c('h4',[_vm._v(" Recurring Until Canceled ")])])],1)])],1),_c('validation-provider',{attrs:{"name":"$t('Ends On (Optional)')"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{staticClass:"p-0",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Ends On (Optional)'),"label-for":"recurring-end-date","label-cols-md":"2"}},[_c('b-form-datepicker',{attrs:{"id":"recurring-end-date","disabled":_vm.recurringInvoiceData.recur_until_cancel === 1,"local":"en","today-variant":"danger","state":errors.length > 0 ? false:null,"min":_vm.recurringInvoiceData.start_date},on:{"input":_vm.calculateOccurrences},model:{value:(_vm.recurringInvoiceData.end_date),callback:function ($$v) {_vm.$set(_vm.recurringInvoiceData, "end_date", $$v)},expression:"recurringInvoiceData.end_date"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)],1)]}}],null,false,110368253)})],1),_c('b-col',{staticClass:"p-0",attrs:{"cols":"12"}},[_c('b-form-checkbox',{attrs:{"checked":"true","name":"check-button","switch":"","inline":"","value":1,"unchecked-value":0},model:{value:(_vm.recurringInvoiceData.send_mail_settings.enabled),callback:function ($$v) {_vm.$set(_vm.recurringInvoiceData.send_mail_settings, "enabled", $$v)},expression:"recurringInvoiceData.send_mail_settings.enabled"}},[_c('h4',[_vm._v(" Send invoice as email ")])])],1),_c('hr'),(_vm.recurringInvoiceData.send_mail_settings.enabled)?_c('b-col',{attrs:{"md":"12","lg":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('To'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"To","label-cols-md":"2","label-for":"mailto"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-center column-is-mobile"},[_c('b-form-tags',{attrs:{"add-on-change":true,"size":"lg","input-id":"mailto","input-attrs":{ 'aria-describedby': 'tags-validation-help' },"placeholder":"Enter a receiver mail and press enter...","tag-validator":_vm.emailListValidation,"separator":" ","state":errors.length > 0 ? false:null},model:{value:(_vm.recurringInvoiceData.send_mail_settings.receivers),callback:function ($$v) {_vm.$set(_vm.recurringInvoiceData.send_mail_settings, "receivers", $$v)},expression:"recurringInvoiceData.send_mail_settings.receivers"}}),(!_vm.showCc)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-1",attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){_vm.showCc=true}}},[_vm._v(" Cc ")]):_vm._e()],1),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)],1)]}}],null,false,339524251)}),(_vm.recurringInvoiceData.send_mail_settings.enabled && _vm.showCc)?_c('validation-provider',{attrs:{"name":_vm.$t('Cc')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Cc","label-cols-md":"2","label-for":"mail-cc"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-center column-is-mobile"},[_c('b-form-tags',{attrs:{"size":"lg","input-id":"mail-cc","input-attrs":{ 'aria-describedby': 'tags-validation-help' },"add-on-change":true,"placeholder":_vm.$t('Enter a cc mail...'),"tag-validator":_vm.emailListValidation,"separator":" ","state":errors.length > 0 ? false:null},model:{value:(_vm.recurringInvoiceData.send_mail_settings.cc),callback:function ($$v) {_vm.$set(_vm.recurringInvoiceData.send_mail_settings, "cc", $$v)},expression:"recurringInvoiceData.send_mail_settings.cc"}}),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-1",attrs:{"variant":"outline-warning","size":"sm"},on:{"click":function($event){_vm.recurringInvoiceData.send_mail_settings.cc = null; _vm.showCc=false;}}},[_vm._v(" Remove ")])],1),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)],1)]}}],null,false,3962094502)}):_vm._e(),(_vm.recurringInvoiceData.send_mail_settings.enabled && _vm.showBcc)?_c('validation-provider',{attrs:{"name":_vm.$t('Bcc')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Bcc","label-cols-md":"2","label-for":"mail-bcc"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-center column-is-mobile"},[_c('b-form-tags',{attrs:{"size":"lg","input-id":"mail-bcc","input-attrs":{ 'aria-describedby': 'tags-validation-help' },"add-on-change":true,"placeholder":"Enter a bcc mail...","tag-validator":_vm.emailListValidation,"separator":" ","state":errors.length > 0 ? false:null},model:{value:(_vm.recurringInvoiceData.send_mail_settings.bcc),callback:function ($$v) {_vm.$set(_vm.recurringInvoiceData.send_mail_settings, "bcc", $$v)},expression:"recurringInvoiceData.send_mail_settings.bcc"}}),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-1",attrs:{"variant":"outline-warning","size":"sm"},on:{"click":function($event){_vm.recurringInvoiceData.send_mail_settings.bcc = null; _vm.showBcc=false;}}},[_vm._v(" Remove ")])],1),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)],1)]}}],null,false,850143989)}):_vm._e(),(_vm.recurringInvoiceData.send_mail_settings.enabled)?_c('validation-provider',{attrs:{"name":_vm.$t('Subject'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Subject","label-cols-md":"2","label-for":"subject"}},[_c('b-form-input',{attrs:{"id":"subject","size":"lg","placeholder":"Enter a mail subject...","state":errors.length > 0 ? false:null},model:{value:(_vm.recurringInvoiceData.send_mail_settings.subject),callback:function ($$v) {_vm.$set(_vm.recurringInvoiceData.send_mail_settings, "subject", $$v)},expression:"recurringInvoiceData.send_mail_settings.subject"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)],1)]}}],null,false,340704781)}):_vm._e(),(_vm.recurringInvoiceData.send_mail_settings.enabled)?_c('validation-provider',{attrs:{"name":_vm.$t('Content'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Content","label-cols-md":"2","label-for":"content"}},[_c('b-form-textarea',{attrs:{"id":"subject","size":"lg","rows":"4","placeholder":"Enter a mail subject...","state":errors.length > 0 ? false:null},model:{value:(_vm.recurringInvoiceData.send_mail_settings.content),callback:function ($$v) {_vm.$set(_vm.recurringInvoiceData.send_mail_settings, "content", $$v)},expression:"recurringInvoiceData.send_mail_settings.content"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)],1)]}}],null,false,3019278431)}):_vm._e()],1):_vm._e()],1)])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }