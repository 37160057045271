<template>

  <div>
    <b-modal
      id="invoiceSendModal"
      ref="invoiceSendModal"
      title="Send Invoice Mail"
      hide-footer
      size="lg"
    />
    <upgrade-plan-modal ref="refUpgradePlanModal" />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-overlay
        id="overlay-background"
        :show="pageIsLoading"
        variant="light"
        opacity="0.85"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <b-card

            class="text-center"
          >

            <h2>
              <b-spinner

                class="mr-1"
                label="Loading"
              />
              {{ overlayMsg }}
            </h2>

          </b-card>
        </template>
        <div
          class="m-2"
        >
          <hr>
        </div>
        <div>

          <b-table
            ref="refInvoiceListTable"
            style="min-height:300px;"
            class="position-relative"
            :items="invoiceData.content.recurredInvoices"
            responsive
            :fields="tableColumns"
            primary-key="id"
            select-mode="single"
            :sort-by.sync="sortBy"
            striped
            hover

            show-empty
            :sort-desc.sync="isSortDirDesc"
            selectable
            empty-text="No matching records found"
            @row-selected="onRowSelected"
          >
            <template #cell(invoice_number)="data">
              <strong>#{{ data.item.invoice_number }} </strong>

            </template>

            <template #cell(name)="data">
              <span class="font-weight-bold d-block text-nowrap">{{ data.item.name }}</span>
            </template>
            <template #cell(content.billing)="data">
              <span v-if="!data.item.client_name"> [[ Client name not found]]</span>
              <span v-else> {{ data.item.client_name }}</span>
            </template>
            <template #cell(content.invoice_date)="data">
              <span> {{ $format_date(data.item.invoice_date) }}</span>

            </template>
            <template #cell(content.due_date)="data">
              <span> {{ $format_date(data.item.due_date) }}</span>

            </template>
            <template #cell(content.paid_date)="data">

              <span v-if="data.item.paid_description"> {{ $format_date(data.item.paid_date) }} | {{ data.item.paid_type_name }} | Note: {{ data.item.paid_description }}</span>
              <span v-else> {{ $format_date(data.item.paid_date) }} | {{ data.item.paid_type_name }} </span>

            </template>
            <template #cell(total)="data">
              <span> {{ data.item.currency_code }} {{ $staticParams.formatCurrency(data.item.total) }}</span>

            </template>

            <!-- Column: Actions -->
            <template
              #cell(actions)="data"
            >

              <b-button
                v-if="data.item.limit_disabled && !invoiceData.has_limit"
                size="sm"
                variant="danger"
                @click="openUpgradeModal"
              >No Limit</b-button>

              <b-button
                v-else-if="data.item.limit_disabled && invoiceData.has_limit"
                size="sm"
                variant="warning"
                @click="createLimitedInvoice(data.item)"
              >Create</b-button>

              <b-dropdown
                v-else
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  v-if="filterType=='unpaid'"
                  variant="primary"
                  @click="markAsPaid(data.item)"
                >

                  <span class="align-middle font-weight-bolder">{{ $t('Mark as paid') }}</span>
                </b-dropdown-item>
                <hr>
                <b-dropdown-item

                  variant="primary"
                  @click="sendEmail(data.item.id)"
                >

                  <span class="align-middle font-weight-bolder">{{ $t('Email') }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="true==false"

                  variant="primary"
                  @click="openInvoiceSendModal(data.item)"
                >

                  <span class="align-middle font-weight-bolder">{{ $t('Send Mail') }}</span>
                </b-dropdown-item>
                <b-dropdown-item

                  variant="primary"
                  @click="toPdf(data.item)"
                >

                  <span class="align-middle font-weight-bolder">{{ $t('Print') }}</span>
                </b-dropdown-item>

                <b-dropdown-item
                  variant="primary"
                  @click="duplicateInvoice(data.item)"
                >
                  <span class="align-middle font-weight-bolder">{{ $t('Duplicate') }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  variant="danger"
                  @click="deleteData(data.item.id)"
                >
                  <feather-icon icon="Trash2Icon" />
                  <span class="align-middle ml-50 font-weight-bolder">{{ $t('Delete') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('Entries') }}</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalInvoices"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>

        </div>
        <mark-as-paid-component
          ref="markAsPaidComponent"
          @paided="markAsPaidhandled"
        />
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BDropdown, BDropdownItem, BPagination, BOverlay, BSpinner, BModal, BButton,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import {
  ref, onUnmounted, watch, computed, toRefs,
} from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import upgradePlanModal from '@core/components/information-components/UpgradePlanModal.vue'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import invoiceStoreModule from '../invoiceStoreModule'
import markAsPaidComponent from '../MarkAsPaid.vue'

export default {
  components: {
    BCard,
    BRow,
    BModal,
    upgradePlanModal,
    BCol,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BSpinner,
    markAsPaidComponent,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    invoiceData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedInvoice: null,
      showPrint: false,
      overlayMsg: 'Loading...',
    }
  },
  watch: {
    filterType() {
      this.$refs.refInvoiceListTable.refresh()
    },
    '$route.query.filterOption': 'updateFilterOption',
  },
  created() {
    // Check for the filterOption query parameter and set the filterOption accordingly
    this.fetchTickets()
  },
  methods: {
    updateFilterOption(newFilterOption) {
      // Handle the change in filterOption
      if (newFilterOption) {
        this.filterOption = newFilterOption
      }
    },
    // eslint-disable-next-line no-unused-vars
    markAsPaidhandled(paidData) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Invoice marked as paid',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
       this.$refs.refInvoiceListTable.refresh()
    },

    createLimitedInvoice(id) {
      this.$swal({
        title: this.$t('Are you sure?'),
        text: this.$t('This recurring invoice hasnt been created due to exceeded limit. Do you want to continue and create this invoice?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes, continue'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.pageIsLoading = true
          this.overlayMsg = 'Invoice saving..'
            store
              .dispatch('app-recurring-invoice/createLimitedInvoice', id)
              // eslint-disable-next-line no-unused-vars
              .then(response => {
                this.isSuccess = true
                this.errorData = null
                this.successMsg = 'Invoice  created'
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Invoice Created',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                  },
                })
                this.pageReady = false

                setTimeout(() => { window.location.href = `/invoices/edit/${response.data.data.id}?activetab=send` }, 1500)
                // this.goToBack()
              })
              .catch(error => {
                this.pageIsLoading = false
                if (error.response.status === 422) {
                  this.errorData = error.response.data
                } else if (error.response.status === 402) {
                  this.$refs.refUpgradePlanModal.show()
                }
              })
            }
  })
  },
  openUpgradeModal() {
    this.$refs.refUpgradePlanModal.show()
  },

    openInvoiceSendModal(invoiceData) {
      this.selectedInvoice = invoiceData
      this.$refs.invoiceSendModal.show()
    },
    duplicateInvoice(invoiceData) {
      this.$swal({
        title: this.$t('Are you sure?'),
        text: this.$t('The invoice will be duplicated. Do you want to continue?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes, continue'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
      this.overlayMsg = 'Duplicating the invoice...'
      this.pageIsLoading = true
      store
        .dispatch('app-recurring-invoice/duplicateInvoice', invoiceData)
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.isSuccess = true
          this.errorData = null
          this.successMsg = 'Invoice duplicated'
          this.pageIsLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Invoice duplicated',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.navigateEdit(response.data.data.id)
        })
        .catch(error => {
          this.pageIsLoading = false
                if (error.response.status === 422) {
                  this.errorData = error.response.data
                } else if (error.response.status === 402) {
                  this.$refs.refUpgradePlanModal.show()
                }
        })
      }
    })
    },
    handleInvoiceSend(sendData) {
      // this.saveData()
      this.sendInvoiceMail(sendData)
    },
    sendInvoiceMail(mailContent) {
      this.overlayMsg = 'Invoice sending to mail...'
      this.pageIsLoading = true
      store
        .dispatch('app-recurring-invoice/sendMail', { invoice_id: this.selectedInvoice.id, content: mailContent })
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.isSuccess = true
          this.errorData = null
          this.successMsg = 'Invoice sended'
          this.pageIsLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Invoice sent by e-mail',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.pageIsLoading = false
          if (error.response.status === 422) {
            this.errorData = error.response.data
          }
        })
    },
    handleCreateInvoiceButton() {
      const { limits } = this.$user()

      let access = true
      if (limits.remaining_invoice_limit != null) {
        if (limits.remaining_invoice_limit <= 0) {
          access = false
          this.$refs.refUpgradePlanModal.show()
        }
      }

      if (access) {
        this.$destroy()
        this.$router.replace({ name: 'invoices-add' })
      }
    },
    handleTestRecurring() {
      store.dispatch('app-recurring-invoice/recurringTest')
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Invoice was deleted',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.$refs.refInvoiceListTable.refresh()
            })
            .catch(() => {

            })
    },

    pdfHasStartedGeneration() {

    },
    toPdf(invoiceData) {
      this.overlayMsg = 'Printing...'
      const excelUrl = `${process.env.VUE_APP_API_URL}/api/invoices-download/${invoiceData.id}?token=${useJwt.getToken()}`
      window.location.href = `${excelUrl}`
      /* changed as backend downloadable
      this.pageIsLoading = true
      this.selectedInvoice = invoiceData
      this.showPrint = true

      setTimeout(() => this.$refs.html2Pdf.generatePdf(), 3000)
      */
    },
    pdfHasGenerated() {
      setTimeout(() => {
        this.showPrint = false
        this.selectedInvoice = false
        this.pageIsLoading = false
        this.overlayMsg = 'Loading...'
      }, 3000)
    },
    markAsPaid(invoiceData) {
      this.$refs.markAsPaidComponent.showEditor(invoiceData)
    },
    onRowSelected(items) {
      if (items && (items[0].limit_disabled !== 1)) {
        this.$destroy()
        this.$router.replace({ name: 'invoices-edit', params: { id: items[0].id } })
      }
    },
    deleteData(id) {
      this.$swal({
        title: this.$t('Are you sure ?'),
        text: this.$t('You are about to delete a invoice. This action cannot be undone'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes, delete now'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-recurring-invoice/deleteInvoiceSingle', { id })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Invoice was deleted',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.$refs.refInvoiceListTable.refresh()
            })
            .catch(() => {

            })
        }
      })
    },
    filterInvoices() {
      if (this.filterOption === 'custom') {
        // Filter invoices based on the custom date range
        if (this.startDate != null && this.endDate != null) {
          this.startDate = new Date(this.startDate)
          this.endDate = new Date(this.endDate)
          this.$refs.refInvoiceListTable.refresh()
        }
      } else {
        this.startDate = null
        this.endDate = null
        this.$refs.refInvoiceListTable.refresh()
      }
    },
    sendEmail(invoiceId) {
      this.$destroy()
      this.$router.push({
        name: 'invoices-edit',
        params: {
          id: invoiceId,
          activeTab: 'send',
        },
      })
    },
    navigateEdit(invoiceId) {
      this.$destroy()
      this.$router.push({
        name: 'invoices-edit',
        params: {
          id: invoiceId,
        },
      })
    },
  },
  setup(props) {
    const APP_STORE_MODULE_NAME = 'app-recurring-invoice'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    const { invoiceData } = toRefs(props) // Access invoiceData prop correctly
    const filterType = ref('unpaid')
    const filterOption = ref('all')
    const startDate = ref(null)
    const endDate = ref(null)
    const perPageOptions = [10, 25, 50, 100]
    const perPage = ref(20)
    const searchQuery = ref('')
    const totalInvoices = ref(0)
    const firstDataCreated = ref(false)
    const refInvoiceListTable = ref(null)
    const currentPage = ref(1)
    const pageIsLoading = ref(true)
    const sortBy = ref('content.invoice_number')
    const isSortDirDesc = ref(true)
    const invoicesList = ref([])
    const unpaidTableColumns = [
      {
        key: 'invoice_number', label: 'Number', sortable: true,
      },
      { key: 'content.billing', label: 'INVOICE', sortable: true },
      { key: 'content.invoice_date', label: 'Date', sortable: true },
      { key: 'content.due_date', label: 'Due date', sortable: true },
      { key: 'total', label: 'Total', sortable: false },
      {
        key: 'actions', thClass: 'text-right', label: 'ACTIONS', tdClass: 'text-right',
      },
    ]
    const paidTableColumns = [
      {
        key: 'invoice_number', label: 'Number', sortable: true,
      },
      { key: 'content.billing', label: 'INVOICE', sortable: true },
      { key: 'content.invoice_date', label: 'Date', sortable: true },
      {
      key: 'content.paid_date', label: 'PAID INFO', thStyle: 'width:30%', sortable: true,
      },
      { key: 'total', label: 'Total', sortable: false },

      {
      key: 'actions', label: 'ACTIONS', thClass: 'text-right', tdClass: 'text-right',
      },
    ]
    const tableColumns = ref(unpaidTableColumns)

    const refetchData = () => {
      refInvoiceListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery], () => {
      refetchData()
    })
    const dataMeta = computed(() => {
      const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalInvoices.value,
      }
    })
    const fetchTickets = () => {
      pageIsLoading.value = true
          if (filterType.value === 'paid') {
            tableColumns.value = paidTableColumns
          } else if (filterType.value === 'unpaid') {
            tableColumns.value = unpaidTableColumns
          }
          if (invoiceData.value.content.recurredInvoices) {
          const { data } = invoiceData.value.content.recurredInvoices
          const { total } = invoiceData.value.content.recurredInvoices.length
          invoicesList.value = data
          totalInvoices.value = total
          firstDataCreated.value = total > 0
          }
          pageIsLoading.value = false
    }
    return {

      tableColumns,
      // Filter
      perPageOptions,
      perPage,
      dataMeta,
      fetchTickets,
      searchQuery,
      totalInvoices,
      firstDataCreated,
      currentPage,
      filterType,
      refetchData,
      // UI
      refInvoiceListTable,
      pageIsLoading,
      isSortDirDesc,
      sortBy,
      filterOption,
      startDate,
      endDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
@media (max-width: 800px) {
  .column-is-mobile {
    flex-direction: column;
  }
}
.table-width{
  max-width:150px;
}
.popover .b-form-datepicker {
  width: 100%;
  max-width: 150px;
  display: inline-block;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
